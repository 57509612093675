<template>
  <div class="template-facture-mensuel">
    <div
      v-if="famille != 'support'"
      class="default-color-statue"
      :class="{
        'black-color-statue': item.etat == 'Créé',
        'green-color': item.etat == 'Payé',
        'red-color montant-bare': item.etat == 'Annulée',
        'color-som-text': item.type === 'separation'
      }"
    >
      {{ item.ttc }} €
    </div>
    <editable-montant
      v-if="famille == 'support'"
      class="mr-1"
      :showCoef="false"
      :item="item"
      column="ttc"
      :updateFunction="updateCoefFrais"
      columnCoef="coef"
      :editable="
        checkPermission('GGDFTHMCF') && item.type_facture == 'Facture proforma'
      "
      uniteMontant=" €"
    />

    <div class="flex-div">
      <div
        v-if="
          item.id != null &&
            item.type !== 'separation' &&
            permissionDownloadFacture
        "
        title="Télécharger facture"
        @click.prevent="downloadFacture()"
        class="block-icon-table-facture ml-1 mr-1"
      >
        <font-awesome-icon icon="arrow-alt-circle-down" />
      </div>
      <div
        v-if="
          item.avoir_id != null &&
            item.type !== 'separation' &&
            permissionDownloadFactureAvoir
        "
        title="Télécharger Facture Avoir"
        @click.prevent="downloadFactureAvoir()"
        class="block-icon-table-facture-avoir ml-1 mr-1"
      >
        <font-awesome-icon icon="arrow-alt-circle-down" />
      </div>
      <div
        class="block-icon-table-facture ml-1 mr-1"
        title="Valider la facture"
        @click.prevent="getTemplateFacture"
        v-if="
          item.id &&
            editable &&
            permissionValidationFacture &&
            item.type !== 'separation'
        "
      >
        <font-awesome-icon icon="file-code" />
      </div>
    </div>

    <v-dialog
      v-model="dialog"
      max-width="1400px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-show"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"> Confirmer Facture</v-label>

          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal()"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card center-text mt-3">
          <div
            v-if="loader == true"
            class="loading-custom-template-block-loder"
          >
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#704ad1'"
              class="loeder"
            />
          </div>
          <v-row v-if="getterTemplate && !loader">
            <v-col col xs="4" sm="4" md="4" class="mt-2">
              <v-menu :close-on-content-click="false" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="getterTemplate.date"
                    label="Date de Facture"
                    outlined
                    readonly
                    hide-details
                    clearable
                    v-on="on"
                    v-bind="attrs"
                    :persistent-placeholder="true"
                    placeholder="Sélectionner un date"
                    prepend-inner-icon="mdi-calendar"
                    :disabled="true"
                    color="#704ad1"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="getterTemplate.date"
                  no-title
                  locale="fr"
                  color="#704ad1"
                >
                </v-date-picker>
              </v-menu>

              <v-select
                v-model="getterTemplate.payment_condition"
                :items="computedConditionPaiement"
                dense
                outlined
                hide-details
                class="mt-3 mb-3"
                label="Conditions du paiement"
                item-text="name"
                item-value="id"
                return-object
                no-data-text="Aucun élément trouvé"
                color="#704ad1"
                item-color="#704ad1"
              ></v-select>
              <v-textarea
                v-model="getterTemplate.description"
                outlined
                label="Description"
                rows="5"
                class="mt-3 mb-0"
                placeholder=""
                :persistent-placeholder="true"
                color="#704ad1"
              ></v-textarea>

              <v-select
                v-model="rib"
                :items="getterTemplate.ribs"
                dense
                outlined
                hide-details
                class="mt-0 mb-3"
                label="Rib"
                item-text="rib"
                item-value="rib"
                return-object
                required
                :loading="loader"
                no-data-text="Aucun élément trouvé"
                :clearable="true"
                :clear-icon="'$clear'"
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                  closeOnClick: true,
                  closeOnContentClick: true
                }"
                color="#704ad1"
                item-color="#704ad1"
              ></v-select>
              <v-radio-group
                v-if="getterTemplate && getterTemplate.id_template"
                label="Choisissez une template"
                v-model="getterTemplate.id_template"
                @change="changeTemplate()"
              >
                <v-radio
                  v-for="(n, index) in getterTemplate.templates"
                  :key="index"
                  :label="n.name"
                  :value="n.id"
                  color="#704ad1"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col col xs="8" sm="8" md="8">
              <div
                class="loading-custom-template-block-loder"
                v-if="getLoadingGetDataTemplateFM && !loader"
              >
                <Half-circle-spinner
                  :animation-duration="1000"
                  :size="50"
                  :color="'#704ad1'"
                  class="loeder"
                />
              </div>
              <iframe
                v-if="getterTemplate && getterTemplate.base64"
                :src="getterTemplate.base64 + '#toolbar=0'"
                height="700"
                width="700"
                id="hmt_template_block_id"
                class="template-style"
              ></iframe>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="errorTemplateNotFound" class="message-error-modal">
            {{ errorTemplateNotFound }}
          </div>
          <div v-if="errorUpload" class="message-error-modal">
            {{ errorUpload }}
          </div>
          <div class="message-error-modal" v-if="errorUploadRib != null">
            {{ errorUploadRib }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleUpload"
            :loading="loadingUpload"
            :class="{ loader: loadingUpload }"
          >
            Valider
          </v-btn>
          <v-btn text @click.prevent="hideModal()">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      errorTemplateNotFound: null,
      loader: false,
      rib: null,
      errorUploadRib: null,
      getLoadingGetDataTemplateFM: false,
      loadingUpload: false,
      errorUpload: null,
      dialog: false
    };
  },
  props: {
    item: { required: true },
    project: { required: true },
    componentName: { required: true },
    editable: { required: true },
    download: { required: false },
    getTemplateMethod: { required: true },
    getterTemplate: { required: true },
    validateMethod: { required: true },
    famille: { required: true },
    permissionDownloadFacture: { required: true },
    permissionDownloadFactureAvoir: { required: true },
    permissionValidationFacture: { required: true }
  },
  computed: {
    ...mapGetters([
      'masterFilialeTemplateList',
      'checkPermission',
      'ConditionPaiement'
    ]),
    computedConditionPaiement() {
      var table = this.ConditionPaiement.map(item => {
        return {
          name: item.name,
          id: item.id
        };
      });
      return [{ name: '', id: null }, ...table];
    }
  },
  methods: {
    ...mapActions([
      'getTemplateAndFiledsDataFraisDynamicToUpdateChange',
      'getSocieteTemplatesListMF',
      'exportPdfFactureMensuel',
      'getAllConditionsPaiement',
      'updateCoefFrais'
    ]),
    async downloadFactureAvoir() {
      await this.exportPdfFactureMensuel({
        id: this.item.avoir_id,
        id_template: this.item.template_id == null ? -1 : this.item.template_id,
        id_master: this.item.vendeur_id
      });
    },
    async downloadFacture() {
      await this.exportPdfFactureMensuel({
        id: this.item.id,
        id_template: this.item.template_id == null ? -1 : this.item.template_id,
        id_master: this.item.vendeur_id
      });
    },
    async handleUpload() {
      this.loadingUpload = true;
      this.errorUpload = null;
      this.errorUploadRib = null;
      if (this.rib == null) {
        this.loadingUpload = false;
        this.errorUpload = 'Une erreur est servenue ! RIB obligatoire';
      } else {
        let response = await this.validateMethod({
          description: this.getterTemplate.description,
          iban: this.rib.iban,
          swift: this.rib.swift,
          bank: this.rib.bank,
          num_facture: this.getterTemplate.num_facture,
          item: this.item,
          date: this.getterTemplate.date,
          date_reglement: this.getterTemplate.date_reglement,
          id_facture: this.item.id,
          id_template: this.getterTemplate.id_template,
          payment_condition: this.getterTemplate.payment_condition
        });
        if (response?.success) {
          this.loadingUpload = false;
          this.errorUpload = null;
          this.hideModal();
        } else {
          this.errorUpload = response?.error;
          this.loadingUpload = false;
        }
      }
    },
    async changeTemplate() {
      let selectedTemplte = this.getterTemplate.templates.filter(
        item => item.id_template == this.getterTemplate.id_template
      );
      if (selectedTemplte.length && selectedTemplte[0].origin) {
        this.getterTemplate.template = selectedTemplte.content.template;
        this.getterTemplate.base64 = selectedTemplte.content.base64;
        this.getLoadingGetDataTemplateFM = false;
        return;
      }
      this.getLoadingGetDataTemplateFM = true;
      const response = await this.getTemplateAndFiledsDataFraisDynamicToUpdateChange(
        {
          id_template:
            this.getterTemplate.id_template == null
              ? -1
              : this.getterTemplate.id_template,
          id_facture: this.item.id,
          id_master:
            this.famille == 'support'
              ? this.item.vendeur_id
              : this.item.filiale_id
        }
      );
      if (response.succes) {
        this.getterTemplate.template = response.data.template;
        this.getterTemplate.base64 = response.data.base64;
        this.getLoadingGetDataTemplateFM = false;
      } else {
        this.getLoadingGetDataTemplateFM = false;
      }
    },
    changeFieldsValue(item) {
      if (item && item.e) {
        this.getterTemplate[item.champName] = item.e;
      }
    },
    hideModal() {
      this.dialog = false;
      this.loader = false;
      this.errorTemplateNotFound = null;
      this.rib = null;
      this.errorUploadRib = null;
      this.getLoadingGetDataTemplateFM = false;
      this.loadingUpload = false;
      this.errorUpload = null;
    },
    async getTemplateFacture() {
      this.errorTemplateNotFound = null;
      this.dialog = true;
      this.loader = true;
      this.getAllConditionsPaiement();

      if (this.item.template_id !== null) {
        this.getTemplateData({
          id_template: this.item.template_id,
          id_facture: this.item.id,
          id_master:
            this.famille == 'support'
              ? this.item.vendeur_id
              : this.item.filiale_id,
          dev: this.famille == 'support' ?? 'no',
          checkId: this.famille == 'support' ?? true
        });
      } else {
        const response = await this.getSocieteTemplatesListMF(this.famille);
        if (response) {
          if (this.masterFilialeTemplateList.length != 0) {
            this.getTemplateData({
              id_template: this.masterFilialeTemplateList[0].id,
              id_facture: this.item.id,
              id_master:
                this.famille == 'support'
                  ? this.item.vendeur_id
                  : this.item.filiale_id,
              dev: this.famille == 'support' ?? 'no',
              np_template_id: this.famille == 'support' ?? true
            });
          } else {
            this.getTemplateData({
              id_template: -1,
              id_facture: this.item.id,
              id_master:
                this.famille == 'support'
                  ? this.item.vendeur_id
                  : this.item.filiale_id,
              dev: this.famille == 'support' ?? 'no',
              np_template_id: this.famille == 'support' ?? true
            });
            this.loader = false;
          }
        }
      }
    },
    async getTemplateData(data) {
      const response = await this.getTemplateMethod(data);
      if (response) {
        if (data.id_template != null) {
          this.getterTemplate.id_template = data.id_template;
        }
        let now = new Date();
        let dateFormate = moment(now).format('YYYY-MM-DD');
        if (this.getterTemplate.day_is_exist == true) {
          if (
            this.getterTemplate &&
            this.getterTemplate.date != null &&
            this.getterTemplate.date != ''
          ) {
            this.getterTemplate.date = moment(this.getterTemplate.date).format(
              'YYYY-MM-DD'
            );
          }
        } else {
          if (this.getterTemplate.type_facture != 'Facture') {
            this.getterTemplate.date = dateFormate;
          }
        }
        if (
          this.getterTemplate.date_reglement == null ||
          this.getterTemplate.date_reglement == ''
        ) {
          this.getterTemplate.date_reglement = dateFormate;
        }

        if (this.getterTemplate.ribs.length) {
          this.rib = this.getterTemplate.ribs[0];
        }
        this.getterTemplate.templates = [
          ...this.getterTemplate.templates,
          { id: -1, name: 'Template Par défaut' }
        ];
        this.loader = false;
      }
    }
  },
  components: {
    editableMontant: () =>
      import(
        '../../ThProject/frais/fraisDynamic/component/EditableInput/EditableMontant.vue'
      )
  }
};
</script>
<style lang="scss" scoped>
.template-facture-mensuel {
  display: flex;
  align-items: center;
}
</style>
